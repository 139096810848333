<template>
  <div class="main">
    <a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules">
      <a-alert
        v-if="isLoginError"
        type="error"
        showIcon
        style="margin-bottom: 24px;"
        :message="loginErrorInfo"
        closable
        :after-close="handleCloseLoginError" />
      <a-form-model-item prop="username" style="margin-bottom:26px;">
        <a-input v-model="form.username" size="large" placeholder="请输入手机号">
          <!-- <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          <img slot="prefix" style="width: 24px;height:24px;" src="~@/assets/homeImg/phone.png" alt="" srcset="">
        </a-input>
      </a-form-model-item>
      <a-form-model-item v-if="!captchaEnabled" prop="password" style="margin-bottom:0;">
        <a-input-password v-model="form.password" size="large" placeholder="请输入密码">
          <!-- <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          <img slot="prefix" style="width: 24px;height:24px;" src="~@/assets/homeImg/pwd.png" alt="" srcset="">
        </a-input-password>
      </a-form-model-item>
      <a-row :gutter="16" v-else>
        <a-col class="gutter-row" :span="16">
          <a-form-model-item prop="code" style="margin-bottom:0;">
            <a-input v-model="form.code" size="large" type="text" autocomplete="off" placeholder="验证码">
              <a-icon slot="prefix" type="security-scan" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="logining"
            :disabled="logining"
            @click="handleSubmit">获取验证码</a-button>
          <!-- <img class="getCaptcha" :src="codeUrl" @click="getCode"> -->
        </a-col>
      </a-row>
      <!-- <a-form-model-item prop="rememberMe">
        <a-checkbox :checked="form.rememberMe" @change="rememberMe">记住密码</a-checkbox>
      </a-form-model-item> -->
      <a-form-item style="margin-top:36px;margin-bottom:0;">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="logining"
          :disabled="logining"
          @click="handleSubmit">确定</a-button>
      </a-form-item>
      <div class="user-login-other" style="opacity: 1;">
        <span>其他方式</span>
        <div class="other-type" >
          <img class="other-type-icon" src="~@/assets/homeImg/qq.png" alt="" srcset="">
          <img class="other-type-icon" src="~@/assets/homeImg/wx.png" alt="" srcset="">
          <img class="other-type-icon" src="~@/assets/homeImg/ali.png" alt="" srcset="">
          <img class="other-type-icon" src="~@/assets/homeImg/iphone.png" alt="" srcset="">
        </div>
        <!--
          ruoyi后台不支持获取是否开启账户.
          故此处不做隐藏处理. 在ruoyi原前端中是在注册页面定义一个属性手动修改处理.
          <router-link class="register" :to="{ name: 'register' }">注册账户</router-link>
        -->

      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
// import { getCodeImg } from '@/api/login'
import { LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_REMEMBERME } from '@/store/mutation-types'
import storage from 'store'

export default {
  components: {
  },
  data() {
    return {
      codeUrl: '',
      isLoginError: false,
      loginErrorInfo: '',
      form: {
        username: 'admin',
        password: 'admin123',
        code: undefined,
        uuid: '',
        rememberMe: false
      },
      rules: {
        username: [{ required: true, message: '请输入帐户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      logining: false,
      captchaEnabled: false,
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.getStorage()
    if (this.captchaEnabled) {
      this.getCode()
    }
  },
  methods: {
    getCode(captchaEnabled) {
      this.captchaEnabled = captchaEnabled
      // getCodeImg().then(res => {
      //   this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled
      //   if (this.captchaEnabled) {
      //     this.codeUrl = 'data:image/gif;base64,' + res.img
      //     this.form.uuid = res.uuid
      //   }
      // })
    },
    getStorage() {
      const username = storage.get(LOGIN_USERNAME)
      const password = storage.get(LOGIN_PASSWORD)
      const rememberMe = storage.get(LOGIN_REMEMBERME)
      if (username) {
        this.form = {
          username: username,
          password: password,
          rememberMe: rememberMe
        }
      }
    },
    rememberMe(e) {
      this.form.rememberMe = e.target.checked
    },
    ...mapActions(['Login', 'Logout']),
    handleSubmit() {
      this.logining = true
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.rememberMe) {
            storage.set(LOGIN_USERNAME, this.form.username)
            storage.set(LOGIN_PASSWORD, this.form.password)
            storage.set(LOGIN_REMEMBERME, this.form.rememberMe)
          } else {
            storage.remove(LOGIN_USERNAME)
            storage.remove(LOGIN_PASSWORD)
            storage.remove(LOGIN_REMEMBERME)
          }
          this.$store.dispatch('Login', this.form).then((res) => {
            console.log('999', res)
            console.log('$store.state>>>>>>', this.$store.state)

            this.$router.push({ path: this.redirect || '/' }).catch(() => { })
          }).catch((err) => {
            console.log('err', err)
            this.loading = false
            setTimeout(() => {
              this.logining = false
            }, 1000)
            if (this.captchaEnabled) {
              this.getCode()
            }
          })
          // this.Login(this.form)
          //   .then((res) => this.loginSuccess(res))
          //   .catch(err => this.requestFailed(err))
          //   .finally(() => {
          //     this.logining = false
          //   })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    loginSuccess(res) {
      this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      this.handleCloseLoginError()
    },
    requestFailed(err) {
      this.isLoginError = true
      this.loginErrorInfo = err
      this.form.code = undefined
      if (this.captchaEnabled) {
        this.getCode()
      }
    },
    handleCloseLoginError() {
      this.isLoginError = false
      this.loginErrorInfo = ''
    }
  }
}
</script>

<style lang="less" scoped>

// .main{
//   width: 1920px;
//   height: 1080px;
//   overflow: hidden;
//   background:#d9e2ef url('./img/br.png') no-repeat 100% 46px;

//   &.loginpage-6 {
//     background: url('./img/bg-1.png') no-repeat 100% 100%;
//   }
// }
.user-layout-login {

  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // text-align: left;
    margin-top: 29px;
    padding-bottom: 31px;
    // line-height: 22px;

    // .register {
    //   float: right;
    // }

    span {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    .other-type {

      .other-type-icon {
        width: 32px;
        height:32px;
        margin-right: 12px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }

    }
  }

  :deep .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
    background: #F7F7F7;
  }

  // :deep .ant-input-affix-wrapper {
  //   background: #F7F7F7;
  // }
}

</style>
